import React, { Component } from 'react';

import SignInForm from './SignInForm';
import SignUpLink from '../SignUp/SignUpLink';
import PasswordForgetLink from '../PasswordForget/Link';

import style from './Signin.scss';

class SignIn extends Component {
  render() {
    return (
      <div className={style.login}>
        <div className={style.content}>
          <div className={style.inner}>
            <div className={style.header}>
              <div className={style.title}>Sign In</div>
            </div>
            <div className={style.loginForm}>
              <div className={style.form}>
                <SignInForm />
              </div>
              <div className={style.passwordForget}>
                <PasswordForgetLink />
              </div>
            </div>
            <div className={style.signup}>
              <SignUpLink isRegister />
            </div>
          </div>
        </div>
        <div className={style.right} />
      </div>
    );
  }
}

export default SignIn;
